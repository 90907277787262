
.stripe-element
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05)
  border-radius: 4px
  line-height: 1.5
  border: 1px solid #dbdbdb
  padding: 1em

.focused-stripe-element
  border-color: var(fas fa-fw fa)

.invalid-stripe-element
  border-color: var(--danger)

.complete-stripe-element
  border-color: var(--success)
