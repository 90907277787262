
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$font-family-base: 'Jost', sans-serif;


@import "custom_bootstrap_vars";
@import "bootstrap";
:root {
  --primary: #{$primary};
  --danger: #{$danger};
  --success: #{$success};
  --gray: #{$gray-500};
}
@import 'node_modules/vanillajs-datepicker/sass/datepicker-bs5';

@import "subscriptions";
@import "stripe";
@import "navbar";
@import "profile";
@import "bootstrap/layout";
@import "bootstrap/subscriptions";


//body {
//  font-family: 'Jost', sans-serif;
//}

// project specific CSS goes here

////////////////////////////////
		//Variables//
////////////////////////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////////////////////////
		//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}


#id_diet_restrictions{
  height: 100px;
}

tbody > tr:last-child > td {
  border-bottom: 0;
}


.btn-primary {
  color: white !important;
}
