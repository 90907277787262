$primary: #58b76b;
$secondary: #1A1E4C;
$success: #564394;
$danger: #dc3545;
$warning: #F9CD47;
$info: #9A8CBD;
$light: #F3F7FE;
$dark: #333333;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark
);
